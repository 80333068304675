import React from 'react'

import { DesktopComputerIcon } from '@heroicons/react/outline'
import Xbox from '../../images/xbox_logo.inline.svg'
import PS5 from '../../images/ps_logo.inline.svg'
import NSwitch from '../../images/ns_logo.inline.svg'
import GNow from '../../images/geforce_now_logo.inline.svg'
import Stadia from '../../images/stadia_logo.inline.svg'
import pc from '../../videos/pc.mp4'
import stadia from '../../videos/stadia.mp4'
import xbox from '../../videos/xbox.mp4'
import playstation from '../../videos/playstation.mp4'
import nintendo from '../../videos/nintendo.mp4'
import nvidia from '../../videos/nvidia.mp4'

const filterList = [
    { video: pc, icon: <DesktopComputerIcon className="w-9 sm:w-14 z-10 pointer-events-none" /> },
    { video: xbox, icon: <Xbox className="text-white w-12 sm:w-16 z-10 pointer-events-none" /> },
    { video: playstation, icon: <PS5 className="text-white w-20 sm:w-32 z-10 pointer-events-none" /> },
    { video: nintendo, icon: <NSwitch className="text-white w-12 sm:w-16 z-10 pointer-events-none" /> },
    { video: nvidia, icon: <GNow className="w-20 sm:w-32 z-10 pointer-events-none" /> },
    { video: stadia, icon: <Stadia className="z-10 pointer-events-none" /> }
]

const FilterItem = ({ video, icon }) => {
    return (
        <li className="group relative text-white p-4 m-2 xl:p-6 xl:m-6 rounded bg-gray-800 border-gray-600 border-2 hover:border-gray-300 hover:scale-110 transition-all flex justify-center">
            {
                video ?
                    <video
                        className="absolute top-0 left-0 w-full z-0 h-full object-cover opacity/0 group-hover:opacity/75 transition-all" 
                        src={video}
                        muted
                        loop
                        onMouseEnter={event => { event.target.focus(); event.target.play() }} 
                        onMouseLeave={event => event.target.pause()} 
                        ></video>
                    : null
            }
            {icon}
        </li>
    )
}

export default function HeroFilter() {
    return (
        <div className="container mx-auto">
            <ul className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6">
                {
                    filterList.map(({ video, icon }, id) => {
                        return <FilterItem video={video} icon={icon} key={id} />
                    })
                }
            </ul>
        </div>
    )
}
