import React from "react";
import HeroSlider from "../components/HeroSlider";
import Header from "../components/Header";
import HeroFilter from "../components/HeroFilter";

// markup
const IndexPage = () => {
  return (
    <div className="px-8">
      <Header />
      <main className="">
        <HeroSlider />
        <HeroFilter />


      </main>

    </div>
  )
}

export default IndexPage
