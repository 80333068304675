import React from 'react'

import { StaticImage } from "gatsby-plugin-image"
import { HomeIcon } from '@heroicons/react/outline'

export default function Header() {
    return (
        <nav className="flex py-5 sm:px-5 sticky justify-between">
        <ul className="flex">
          <StaticImage className="h-12 w-12 mr-6" src="../../images/icon.png" alt="gatsbylogo" />
          <li className="py-3 px-5 text-white hidden sm:flex"><HomeIcon className="w-5 h-5 mr-2" />Startseite</li>
          <li className="py-3 px-5 text-white hidden sm:flex"><HomeIcon className="w-5 h-5 mr-2" />Suche</li>
          <li className="py-3 px-5 text-white hidden sm:flex"><HomeIcon className="w-5 h-5 mr-2" />Meine Liste</li>
          {/* <li className="py-3 px-5 text-white flex"><HomeIcon className="w-5 h-5 mr-2" />Originals</li>
          <li className="py-3 px-5 text-white flex"><HomeIcon className="w-5 h-5 mr-2" />Filme</li>
          <li className="py-3 px-5 text-white flex"><HomeIcon className="w-5 h-5 mr-2" />Serien</li> */}
        </ul>
        <div className="flex items-center justify-self-end">
          <span className="text-white mr-2">Profil</span>
          <StaticImage className="h-12 w-12 sm:mr-6" src="../../images/icon.png" alt="gatsbylogo" />
        </div>
      </nav>
    )
}
