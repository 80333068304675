import React from 'react'

import { Splide, SplideSlide } from '@splidejs/react-splide'

import '@splidejs/splide/dist/css/splide.min.css';

export default function HeroSlider() {
    return (
        <Splide
            className="my-4"
            options={{
                rewind: true,
                autoplay: true,
                type: 'loop',
                padding: '5rem',
                fixedHeight: '25rem',
                cover: true,
                gap: '1rem',
                speed: 700,
                arrows: false,
                breakpoints: {
                    1024: {
                        perPage: 1,
                        fixedHeight: '175px',
                        padding: 0
                    }
                }
            }}>
            <SplideSlide className="border-transparent border-4 rounded hover:border-white transition-all">
                <img className="object-cover object-top w-full h-full" src="https://images.ctfassets.net/j95d1p8hsuun/56EbUlUnLAcehYTuY6wzxa/f647a4955a99359badc2497fe2dc886a/AboutNW-desktop.png" alt="new world" />
            </SplideSlide>
            <SplideSlide className="border-transparent border-4 rounded hover:border-white transition-all">
                <img className="object-cover object-top w-full h-full" src="https://images.ctfassets.net/rporu91m20dc/7wTZkg6O7gg3XGh03CGvdw/b2ac02d8835c5e77fd2c6c71c8335c4b/DEATHLOOP-content-hero.jpg?q=70&q=70&fm=webp" alt="death loop" />
            </SplideSlide>
            <SplideSlide className="border-transparent border-4 rounded hover:border-white transition-all">
                <img className="object-cover object-top w-full h-full" src="https://gmedia.playstation.com/is/image/SIEPDC/TEST-desktop-ratchet_hero-background-block-03-en-24jun21?$2400px$" alt="ratchet & clank" />
            </SplideSlide>
        </Splide>
    )
}

